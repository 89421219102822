(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/*
Code supports for Marketing team repo: hp_redesign_code
*/

var __hp={init:function(){var e=window.innerWidth-document.documentElement.clientWidth;document.documentElement.style.setProperty("--scrollBarWidth",e+"px");var t=0;document.body.contains(document.querySelector(".gbl-promo-bar"))?t=document.querySelector(".gbl-promo-bar").offsetHeight:document.body.contains(document.querySelector(".gbl-promo-bar-wrap"))&&(t=document.querySelector(".gbl-promo-bar-wrap").offsetHeight);var o=document.querySelector(".promo-bar").offsetHeight+document.querySelector("#header-wrapper").offsetHeight+t;console.log(o),document.documentElement.style.setProperty("--hdrHeight",o+"px"),document.documentElement.style.setProperty("--padStandard",(window.innerWidth-1252)/2+"px")}};document.addEventListener("DOMContentLoaded",__hp.init),window.addEventListener("resize",__hp.init);

function setProdCall() { 
    $('.page-tiles.three-up .product-call').each(function() {
        let thisImg_ = ($(this).prev().find('img').height() - $(this).outerHeight());

        $(this).css({
            'top': thisImg_ + 'px'
        });
    });

    $('.page-tiles.four-up .product-call').each(function() {
        let thisImg_ = ($(this).prev().find('img').height() - $(this).outerHeight());

        $(this).css({
            'top': thisImg_ + 'px'
        });
    });
}

$(document).ready(function() {
    //hp_app.init();

    let scrollTarg = $(document);
    scrollTarg.on('scroll.plx', function() {
        let strt_ = 0;
        let scrolledY_ = scrollTarg.scrollTop();
        let plInt_ = .66;
        let heroImg_ = $('.hero-fullwidth-img[data-parallax="true"] .hero-image-fullwidth img');
        let parAmt_ = ((scrolledY_ * plInt_) + strt_);
        let parLimit_ = heroImg_.height() - heroImg_.closest('div').height();
        if (parAmt_ < parLimit_) {
            heroImg_.css('top', '-' + parAmt_ + 'px');
        }
    });

    if ($('.promo-card').length > 0) {
        scrollTarg.on('scroll.crd', function() {
            $('.promo-card').each(function() {
                $this_ = $(this); //console.log($this_.offset().top);

                if (($this_.offset().top - 550) < scrollTarg.scrollTop()) {
                    $this_.addClass('scrolled_');
                } else {
                    $this_.removeClass('scrolled_');
                }
            });
        });
    }

    // setProdCall();
    // $(window).on('load resize.pc_', setProdCall);

});

},{}]},{},[1]);
